import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default {
  async rowPedidoCliente (Vue, idpedidocli) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addGT('estado', 0)
      .addExact('idpedidocli', idpedidocli)
    const resp = await Vue.$api.call('pedidocli.select', { filter: apiFilter })
    return resp.data.result.dataset[0]
  },
  async rowLineaPedidoCliente (Vue, idlpedidocli) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addGT('estado', 0)
      .addExact('idlpedidocli', idlpedidocli)
    const resp = await Vue.$api.call('lpedidocli.select', { filter: apiFilter })
    return resp.data.result.dataset[0]
  },
  async idalbarancliAServir (Vue, idpedidocli) {
    const resp = await Vue.$api.call('albarancli.idalbarancliAServir', {
      idpedidocli
    })
    if (resp && resp.data && resp.data.result) {
      return resp.data.result.dataset[0]
    }
  },
  async crearAlbaranDesdePedido (Vue, idpedidocli, fecha) {
    const resp = await Vue.$api.call('albarancli.crearDesdePedido', {
      idpedidocli, fecha
    })
    return resp.data.result.dataset[0]
  },
  async updateDatoArticulo (Vue, iddatoArticulo, valor) {
    await Vue.$api.call('datoArticulo.update', {
      values: {
        iddato_articulo: iddatoArticulo,
        valor
      },
    })
  },
  async servirLinea (Vue, idpedidocli, idalbarancli, idarticulo, nserieInicial, unidades) {
    await Vue.$api.call('lalbarancli.anadirArticuloMasivo', {
      idpedidocli,
      idalbarancli,
      idarticulo,
      nserie_inicial: nserieInicial,
      unidades
    })
  }
}
