<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    >
      <v-btn
        slot="right"
        icon
        @click.stop="clickSubmit($refs.form)"
      >
        <v-icon>{{ $vuetify.icons.values.check }}</v-icon>
      </v-btn>
    </b10-toolbar>
    <b10-page-content
      :padding="3"
    >
      <v-form
        ref="form"
        lazy-validation
      >
        <servir-linea-form
          v-if="lineaPedidoCliente && lineaPedidoCliente.unidades_falta_servir && lineaPedidoCliente.idarticulo"
          :idarticulo="lineaPedidoCliente.idarticulo"
          :unidades-falta-servir="lineaPedidoCliente.unidades_falta_servir"
          @change-values="changeFormData"
        />
      </v-form>
    </b10-page-content>
  </b10-base>
</template>

<script>
import { formPageMixin } from '@/mixins/formPageMixin'
import ServirLineaForm from './components/ServirLineaForm'
import Data from './PedidoClienteServirLineaData'
import { currentDate } from '@/utils/date'

export default {
  components: { ServirLineaForm },
  mixins: [formPageMixin],
  data () {
    return {
      pedidoCliente: null,
      lineaPedidoCliente: null,
    }
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    changeFormData (formData) {
      this.formData = formData
    },
    async loadPage () {
      this.pedidoCliente = await Data.rowPedidoCliente(this, this.routeParams.idpedidocli)
      this.lineaPedidoCliente = await Data.rowLineaPedidoCliente(this, this.routeParams.idlpedidocli)
      this.title = `Servir artículo ${this.lineaPedidoCliente.codigo} - ${this.lineaPedidoCliente.descripcion}`
    },
    async submitForm () {
      this.$loading.showManual()
      try {
        // actualizar datos de artículo (si cambiaron)
        for (let dato of this.formData.dato_articulo) {
          if (dato.valor !== dato.valor_original) {
            await Data.updateDatoArticulo(
              this,
              dato.iddato_articulo,
              dato.valor
            )
          }
        }
        // servir
        let albaranCliente = await Data.idalbarancliAServir(
          this, this.routeParams.idpedidocli
        )
        if (!albaranCliente) {
          albaranCliente = await Data.crearAlbaranDesdePedido(
            this, this.routeParams.idpedidocli, currentDate()
          )
        }
        await Data.servirLinea(
          this,
          this.routeParams.idpedidocli,
          albaranCliente.idalbarancli,
          this.lineaPedidoCliente.idarticulo,
          this.formData.nserie_inicial,
          this.formData.unidades
        )
        const unidadesLabel = this.$options.filters.pluralize('unidad', this.formData.unidades, 'es')
        const articuloLabel = `${this.lineaPedidoCliente.codigo} - ${this.lineaPedidoCliente.descripcion}`
        this.$alert.showSnackbarSuccess(
          `Se han servido ${this.formData.unidades} ${unidadesLabel} del artículo ${articuloLabel}`
        )
      } finally {
        this.$loading.hide()
      }
      this.$appRouter.go(-1)
    },
  },
}
</script>
