import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default {
  async selectDatoArticulo (Vue, idarticulo) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addGT('estado', 0)
      .addExact('idarticulo', idarticulo)
      .addExact('para_fabricacion', true)
    const resp = await Vue.$api.call('datoArticulo.select', { filter: apiFilter })
    return resp.data.result.dataset.map(dato => ({ ...dato, valor_original: dato.valor }))
  },
}
